import { AgdirCardComponent, AgdirConfirmationService, ResponsiveService } from '@agdir/core';
import { Share, SharesService } from '@agdir/domain';
import { CUSTOMER_PATHS } from '@/constants';
import { BadgeComponent } from '@agdir/ui/badge';
import { ButtonComponent } from '@agdir/ui/button';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, Pipe, PipeTransform, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { derivedAsync } from 'ngxtension/derived-async';
import { Invitation, InvitationStatus, InvitationStatusToText } from '../domain/invitation';
import { InvitationService } from '../services/invitation.service';
import { AccessLevelToStringPipe } from './access-level-to-string.pipe';
import { trigger, transition, style, animate } from '@angular/animations';

@Pipe({
	standalone: true,
	name: 'invitationDisplayText',
})
export class InvitationStatusTextPipe implements PipeTransform {
	t = inject(TranslocoService);

	transform(i: Invitation) {
		return this.t.translate('invitations.status.' + InvitationStatusToText.get(i.status));
	}
}

@Component({
	selector: 'agdir-company-invitations-list',
	template: `
		<agdir-card title="invitations.company.title" subtitle="invitations.company.subtitle" class=" md:hidden ag-grid-full">
			<div menu class="flex-row-end gap-1">
				<span transloco="invitations.showAllToggle"></span>
				<nz-switch [(ngModel)]="seeAll"></nz-switch>
			</div>
			@for (i of invitations(); track $index) {
				<div class="border-b w-full" [@slideInOut]>
					<div class="flex flex-row justify-between items-center py-1 cursor-pointer" (click)="toggleVisiblity(i)">
						<div [class.font-bold]="itemVisibility().has(i)" class="transition-all duration-300 mr-auto">
							{{ i.invitee?.name }} {{ i.invitee?.surname }}
						</div>
						@if (itemVisibility().has(i)) {
							<agdir-button icon="arrow_drop_up" type="button" color="outline" label="general.closeText" />
						} @else {
							<agdir-badge
								[color]="i.status === InvitationStatus.PENDING ? 'blue' : i.status === InvitationStatus.ACCEPTED ? 'green' : 'red'"
								label="{{ i | invitationDisplayText }}"
							/>
							<agdir-button icon="visibility" type="button" color="ghost" size="large" />
						}
					</div>
					@if (itemVisibility().has(i)) {
						<div class="w-full grid grid-cols-[1fr_max-content] gap-2 mt-2" [@slideInOut]>
							<div class="text-gray-700">Status</div>
							<div>
								<agdir-badge
									[color]="
										i.status === InvitationStatus.PENDING ? 'blue' : i.status === InvitationStatus.ACCEPTED ? 'green' : 'red'
									"
									label="{{ i | invitationDisplayText }}"
								/>
							</div>
							<div class="text-gray-700">Invited</div>
							<div>{{ i.createdAt | date }}</div>
							<div class="text-gray-700">Number</div>
							<div class="truncate">{{ i.invitee?.phoneNumber }}</div>
							<div class="text-gray-700">Email</div>
							<div class="truncate">{{ i.invitee?.email }}</div>
							<div class="text-gray-700">Role</div>
							<div>{{ i.permission | accessLevelToString }}</div>
						</div>
						<div class="flex-row-center my-2">
							@if (i.status !== InvitationStatus.ACCEPTED) {
								<agdir-button
									(click)="deleteInvitation(i)"
									[disabled]="isDeleting().has(i)"
									[isProcessing]="isDeleting().has(i)"
									[attr.data-cy]="'revokeInvite' + i?.company?.id"
									color="red-ghost"
									icon="delete"
									[label]="'invitations.status.revokeInvite'"
								/>
							}
						</div>
					}
				</div>
			}
		</agdir-card>
		<agdir-card title="invitations.company.title" subtitle="invitations.company.subtitle" class="hidden md:flex ag-grid-full">
			<div menu class="flex-row-end gap-1">
				<span transloco="invitations.showAllToggle"></span>
				<nz-switch [(ngModel)]="seeAll"></nz-switch>
			</div>
			<table class="agdir-table" [class.hidden]="!invitations().length">
				<thead>
					<tr>
						<th>Name</th>
						<th transloco="profilePage.form.email" class="hidden lg:table-cell"></th>
						<th>Number</th>
						<th transloco="invitations.status.invitedAt">Role</th>
						<th class="hidden lg:table-cell">Role</th>
						<th class="hidden lg:table-cell">Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					@for (i of invitations(); track $index) {
						<tr>
							<td>
								<span>{{ i.invitee?.name }} {{ i.invitee?.surname }}</span>
							</td>
							<td class="hidden lg:table-cell">{{ i.invitee?.email }}</td>
							<td>
								{{ i.invitee?.phoneNumber }}
							</td>
							<td>
								<time [innerText]="i.createdAt | date: 'shortDate'"></time>
							</td>
							<td class="hidden lg:table-cell">{{ i.permission | accessLevelToString }}</td>
							<td class="hidden lg:table-cell text-left">
								<agdir-badge
									[color]="
										i.status === InvitationStatus.PENDING ? 'blue' : i.status === InvitationStatus.ACCEPTED ? 'green' : 'red'
									"
									label="{{ i | invitationDisplayText }}"
								/>
							</td>
							<td>
								<div class="flex-row-start">
									<agdir-badge
										class="lg:hidden"
										[color]="
											i.status === InvitationStatus.PENDING ? 'blue' : i.status === InvitationStatus.ACCEPTED ? 'green' : 'red'
										"
										label="{{ i | invitationDisplayText }}"
									/>
									@if (i.status === InvitationStatus.ACCEPTED) {
										<agdir-button
											class="hidden md:block"
											(click)="deleteInvitation(i)"
											[disabled]="isDeleting().has(i)"
											[isProcessing]="isDeleting().has(i)"
											[attr.data-cy]="'revokeInvite' + i?.company?.id"
											color="red-ghost"
											icon="delete"
											[label]="isMobile() || isTablet() ? '' : 'invitations.status.revokeInvite'"
										/>
									}
								</div>
							</td>
						</tr>
					}
				</tbody>
			</table>
		</agdir-card>
	`,
	host: { class: 'flex flex-col gap-1 md:gap-2' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			transition(':enter', [style({ height: 0, opacity: 0 }), animate('200ms ease-out', style({ height: '*', opacity: 1 }))]),
			transition(':leave', [style({ height: '*', opacity: 1 }), animate('200ms ease-in', style({ height: 0, opacity: 0 }))]),
		]),
	],
	imports: [
		AgdirCardComponent,
		TranslocoDirective,
		NzSwitchComponent,
		FormsModule,
		ButtonComponent,
		DatePipe,
		BadgeComponent,
		InvitationStatusTextPipe,
		AccessLevelToStringPipe,
	],
})
export class CompanyInvitationsComponent {
	itemVisibility = signal<Set<Invitation>>(new Set());
	toggleVisiblity(share: Invitation) {
		this.itemVisibility.update((set) => {
			if (set.has(share)) {
				set.delete(share);
			} else {
				set.add(share);
			}
			return set;
		});
	}

	seeAll = model(false);
	showGoToFarm = input(false);
	isDeleting = signal(new Set<Invitation>());
	localInvitations = signal<Invitation[] | null>(null);
	deleteService = inject(AgdirConfirmationService);
	responsiveService = inject(ResponsiveService);
	isMobile = this.responsiveService.isMobile();
	isTablet = this.responsiveService.isTablet(true);
	sharesService = inject(SharesService);
	protected readonly CUSTOMER_PATHS = CUSTOMER_PATHS;
	protected readonly history = history;
	protected readonly InvitationStatus = InvitationStatus;
	private readonly invitationService = inject(InvitationService);
	invitations = derivedAsync(
		async () => {
			const seeAll = this.seeAll();
			const localInvitations = this.localInvitations();
			const i = localInvitations || this.invitationService.currentCompanyInvitesSignal();
			return i?.filter((i) => seeAll || i.status === InvitationStatus.PENDING || i.status === InvitationStatus.EXPIRED) || [];
		},
		{ initialValue: [] },
	);

	async deleteInvitation(i: Invitation) {
		const confirmed = await this.deleteService.genericDelete(`${i.invitee?.name} ${i.invitee?.surname}`);
		if (!confirmed) {
			return;
		}
		i.status = InvitationStatus.DELETED;
		i.changedAt = new Date();
		this.isDeleting.update((s) => s.add(i));
		await this.invitationService.delete(i);
		this.isDeleting.update((s) => {
			s.delete(i);
			return s;
		});
		this.localInvitations.set(this.invitations().filter((inv) => inv.code !== i.code));
	}

	goToFarm(i: Invitation) {
		window.location.href = `/${i.company?.id}`;
	}
}
